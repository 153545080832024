<template>
<!--  打印机客户端页面  -->

  <!--    首页         -->
  <div id="sss" style="width: 100%;
    height: 100%;" class="main_background">
    <div id="title" @click="checkUpdate">打印机自助页面</div>
    <div class="title_gb">
      <img class="title_left" src="../../assets/img/title_left.png" alt="">
      <img class="title_right" src="../../assets/img/title_right.png" alt="">
    </div>

    <div style="    position: absolute;
    text-align: center;
    top: 30%;
    width: 100%;" v-show="!showMsg">
      <van-image
          width="25%"
          height="25%"
          :src="require('../../assets/img/idCard.png')"
          @click="qrCode"
      />
      <!--          src="https://cdn.jsdelivr.net/npm/@vant/assets/cat.jpeg"-->

      <van-image
          style="margin-left: 10%"
          width="25%"
          height="25%"
          :src="require('../../assets/img/qrCode.png')"
          @click="idNum"
      />
      <div id="qrCodeText">扫描二维码</div>
      <div id="idCodeText">扫描身份证</div>
    </div>

    <!--    输入页        -->
    <div v-show="showMsg" class="input_page">
      <div v-show="!showList">
        <div style="margin-top: 20%;color: white;font-size: 90%">
          {{ isQrCode ? "请扫描二维码" : "请填写身份证号码" }}
        </div>
        <van-field
            class="van-cell"
            v-model="value"
            clearable
            label=""
            placeholder=""
            ref="text"
        />
        <div style="text-align: center;margin-top: 2.66667vw">
          <img src="../../assets/img/button_bg.png" style="    width: 22%;">
          <span @click="onOk"
                style="color: white;
              position: absolute;
              left: 44%;">
            下一步
          </span>
        </div>
      </div>
    </div>
    <!--    打印页        -->
    <div style="margin-top: 20%" v-show="showList">
      <gridList
          port-name="osp.weigh.WarehouseConfirm.getPrintList"
          :anonymous="true"
          :request-data="{'idNum': this.value}"
          :dataList="dataList" style="margin: 4.26667vw;" ref="grid"
      >
        <div
            v-for="item in dataList"
            :key="item.id">
          <div class="item">
            <van-image class="image" :src="require('../../assets/img/报告.png')"/>
            <div class="content">
              <div class="tags fs14"><span class="status_green">{{ item['material.name'] }}</span>
                <div class="title fs14">{{ item['carNum'] }}</div>
              </div>
            </div>
            <div class="fs12">
              <van-tag plain type="primary" size="large" @click="printReport(item.fileUrl)"> 打印</van-tag>
            </div>
          </div>

        </div>
      </gridList>
    </div>

  </div>
  <div class="time" v-if="showMsg">
    <van-count-down
        style="font-size: 3.2vw; color: white;"
        :time="time" format="ss 秒后返回，点击立即返回" @finish="timeFinish" ref="countDown" @click="onBack"/>
  </div>


</template>

<script>

import gridList from "../../components/GridList";
import {Toast} from "vant/lib/";
import _ from "lodash";

export default {
  name: "PrintWay",
  components: {gridList},
  data() {
    return {
      value: "",
      showMsg: false,
      showList: false,
      isQrCode: true,
      dataList: [],
      time: 1000 * 60,
      interval: null,
      intervalErrorCount: 0,
    };
  },
  methods: {
    checkUpdate: function () {
      location.reload(true);
    },
    qrCode: function () {
      this.showMsg = true;
      this.isQrCode = true;
      var text = this.$refs.text;

      function a() {
        text.focus()
      }

      setTimeout(a, 300);
    },
    idNum: function () {
      this.showMsg = true;
      this.isQrCode = false;
      var text = this.$refs.text;

      this.interval = setInterval(this.readCert, 2000);

      function a() {
        text.focus()
      }

      setTimeout(a, 300);
    },
    onBack: function () {
      this.value = '';
      this.showMsg = false;
      this.showList = false;
      if (this.interval != null) {
        clearInterval(this.interval);
        this.interval = null;
      }
      this.intervalErrorCount = 0;
    },
    onOk: function () {
      if (_.isEmpty(this.value)) {
        Toast(this.isQrCode ? "请扫描二维码" : "请填写身份证号码");
        return;
      }
      if (this.interval != null) {
        clearInterval(this.interval);
        this.interval = null;
      }
      this.showList = true;
      this.resetTime();
      this.$refs.grid.onRefresh();
    },
    printReport: async function (fileUrl) {
      this.resetTime();
      if (_.isEmpty(fileUrl)) {
        return
      }
      var data = JSON.stringify({
        "fileUrl": fileUrl,
        // "printerName":"TSC MF3400",
        "jobName": "printjob",
        // "pageWidth":"300",
        // "pageHeight":"113",
        // "marginLeft":"10",
        "printDirection": 1,// 0横 1纵
        // "pageCount":"5"
      });

      this.$sapi.post('http://localhost:6689/printpdf', data)

    },
    timeFinish: function () {
      this.onBack();
    },
    resetTime: function () {
      this.$refs.countDown.reset();
    },
    readCert: function () {
      var me = this;
      if (this.isQrCode === true) {
        return
      }
      if (this.intervalErrorCount >= 5) {
        if (this.interval != null) {
          clearInterval(this.interval);
          this.interval = null;
        }
      }

      function onSuccess(data) {
        let dataObject = JSON.parse(data);
        let errorMsg = dataObject.errorMsg;
        let resultFlag = dataObject.resultFlag;
        if (!_.isEmpty(errorMsg) && resultFlag != 0) {
          Toast(errorMsg + " code:" + resultFlag);
          me.intervalErrorCount = me.intervalErrorCount + 1;
          return;
        }
        me.value = dataObject.certNumber;
        // me.onOk();
      }


      var startDt = new Date();
      var options = new Object();
      options.type = "GET";
      //options.url="readcard";
      options.url = "http://127.0.0.1:19196/readcard" + "&picpath=\"D:\\1\\123.bmp\"" + "&" + "t=" + Math.random();	//URL后面加一个随机参数的目的是为了防止IE内核浏览器的数据缓存      options.timeout = 5000;
      options.timeout = 5000;
      options.onSuccess = onSuccess;
      this.ajax(options);
    },
    ajax: function (options) {
      var me = this;
      if (options.type == null) {
        options.type = "POST";
      }

      if (options.url == null) {
        options.url = "";
      }

      if (options.timeout == null) {
        options.timeout = 5000;
      }

      if (options.onComplate == null) {
        options.onComplate = function () {
        }
      }

      if (options.onError == null) {
        options.onError = function () {
          me.intervalErrorCount = me.intervalErrorCount + 1;
        }
      }

      if (options.onSuccess == null) {
        options.onSuccess = function () {
        }
      }

      if (options.data) {
        options.data = "";
      }

      var xml;
      if (typeof ActiveXObject != 'undefined') {
        var aVersions = ["Microsoft.XMLHTTP", "Msxml2.XMLHttp.6.0", "Msxml2.XMLHttp.5.0", "Msxml2.XMLHttp.4.0", "Msxml2.XMLHttp.3.0"];
        for (var i = 0; i < aVersions.length; i++) {
          try {
            xml = new ActiveXObject(aVersions[i]);
          } catch (e) {
            console.log(e)
          }
        }
      } else if (typeof XMLHttpRequest != 'undefined') {
        xml = new XMLHttpRequest();
      }

      xml.open(options.type, options.url, true);

      var timeoutLength = options.timeout;

      var requestDone = false;

      setTimeout(function () {
        requestDone = true;
      }, timeoutLength);

      xml.onreadystatechange = function () {

        if (xml.readyState == 4 && !requestDone) {

          if (httpSuccess(xml)) {

            options.onSuccess(httpData(xml));
          } else {
            options.onError();
          }

          options.onComplate();

          xml = null;
        }
      };

      xml.send();

      function httpSuccess(r) {
        try {
          return !r.status && location.protocol == "file:"
              ||
              (r.status >= 200 && r.status <= 300)
              ||
              r.status == 304
              ||

              navigator.userAgent.indexOf("Safari") >= 0
              && typeof r.status == "undefined";
        } catch (e) {
          console.log(e)
        }
        return false;
      }

      function httpData(r) {
        var ct = r.getResponseHeader("responseType");
        if (ct) {
          if (ct == "script") {
            eval.call(window, data);
          }
          if (ct == "xml") {
            return r.responseXML;
          }
          if (ct == "json") {
            return JSON.parse(r.responseText);
          }
        }
        return r.responseText;
      }
    }
  }
}
</script>

<style scoped>
.input_page {

}

.input_page  :deep(.van-cell) {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: var(--van-cell-vertical-padding) var(--van-cell-horizontal-padding);
  overflow: hidden;
  color: white;
  font-size: var(--van-cell-font-size);
  line-height: var(--van-cell-line-height);
  background-color: rgba(0, 0, 0, 0);
  border: solid;
  border-color: darkcyan;
  border-width: thick;
}

.input_page :deep( .van-field__control) {
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-width: 0;
  margin: 0;
  padding: 0;
  color: #ffffff;
  line-height: inherit;
  text-align: left;
  background-color: transparent;
  border: 0;
  resize: none;
  user-select: auto;
}

.print_page {
  position: absolute;
  padding-top: 20%;
}

.time {
  text-align: right;
  margin-right: 2%;
  position: absolute;
  top: 0%;
  right: 0%;
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
}

@font-face {
  font-family: 'digifacewide';
  src: url('../../assets/font/方正正准黑简体.TTF');
}

.main_background {
  position: fixed;
  background: transparent url(../../assets/img/background.png) no-repeat fixed top;
  background-size: 100% 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

#title {
  font-family: 'digifacewide', serif;
  color: #1bd3e2;
  font-size: 4.5vw;
  position: absolute;
  top: 10.13%;
  left: 34%;
}

.title_gb {
  position: absolute;
  top: 10%;
  left: 14%;
}

.title_gb .title_left {
  position: relative;
}

.title_gb .title_right {
  position: relative;
  left: 94%;
}

#qrCodeText {
  font-size: 2.33333vw;
  color: white;
  position: absolute;
  top: 68%;
  left: 27%;
}

#idCodeText {
  font-size: 2.33333vw;
  color: white;
  position: absolute;
  top: 68%;
  left: 62%;
}

.item {
  display: flex;
  border-radius: 5px;
  flex-direction: row;
  box-shadow: 0 0 2px #ddd;
  align-items: center;
  padding: 7px;
  margin: 2px;
}

.item + .item {
  margin-top: 10px;
}

.item > .image {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.item > .title {
}

.item .status_green {
  color: rgb(0, 179, 134)
}

.item .status_red {
  color: #f83817;
}

.item > .content {
  flex: 1;
}

.item .time {
  color: #666;
}
</style>